exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-corporate-jsx": () => import("./../../../src/pages/corporate.jsx" /* webpackChunkName: "component---src-pages-corporate-jsx" */),
  "component---src-pages-creative-content-jsx": () => import("./../../../src/pages/creativeContent.jsx" /* webpackChunkName: "component---src-pages-creative-content-jsx" */),
  "component---src-pages-fashion-jsx": () => import("./../../../src/pages/fashion.jsx" /* webpackChunkName: "component---src-pages-fashion-jsx" */),
  "component---src-pages-food-jsx": () => import("./../../../src/pages/food.jsx" /* webpackChunkName: "component---src-pages-food-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-logo-design-jsx": () => import("./../../../src/pages/logoDesign.jsx" /* webpackChunkName: "component---src-pages-logo-design-jsx" */),
  "component---src-pages-photography-jsx": () => import("./../../../src/pages/photography.jsx" /* webpackChunkName: "component---src-pages-photography-jsx" */),
  "component---src-pages-prod-labels-jsx": () => import("./../../../src/pages/prodLabels.jsx" /* webpackChunkName: "component---src-pages-prod-labels-jsx" */),
  "component---src-pages-quote-jsx": () => import("./../../../src/pages/quote.jsx" /* webpackChunkName: "component---src-pages-quote-jsx" */),
  "component---src-pages-retail-jsx": () => import("./../../../src/pages/retail.jsx" /* webpackChunkName: "component---src-pages-retail-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-skincare-jsx": () => import("./../../../src/pages/skincare.jsx" /* webpackChunkName: "component---src-pages-skincare-jsx" */)
}

